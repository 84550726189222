/** Imports `pdf-js` dynamically. Will add a worker when executed on the client,
 * as that is required in that environment. */
import type { DocumentInitParameters, PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'

interface GetPdfDocumentParams extends Omit<DocumentInitParameters, 'isEvalSupported' | 'src'> {
  data?: Uint8Array | string // Overwriting the type to disallow Buffer
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const importPdfjs = async () => {
  const pdfjs = await import('pdfjs-dist/legacy/build/pdf.mjs')
  // We have to import this even though we don't use it directly,
  // so that it's available on the final bundle for pdfjs to import
  const _pdfjsWorker = await import('pdfjs-dist/legacy/build/pdf.worker.mjs')

  pdfjs.GlobalWorkerOptions.workerSrc = `pdfjs-dist/legacy/build/pdf.worker.mjs`

  return pdfjs
}

/** Parses a pdf document from it's bytes or URL in a secure way */
export const getPdfDocument = async (src: GetPdfDocumentParams): Promise<PDFDocumentProxy> => {
  const pdfjs = await importPdfjs()

  // This is the only place we want to disable this rule, as we need to use the method here
  // eslint-disable-next-line custom-rules/prefer-get-pdf-document-to-pdfjs-method
  return pdfjs.getDocument({
    ...src,
    // On Node, Buffer is assignable to Uint8Array
    // Guarantee that we are not passing Buffer (which can happen on test mocks)
    ...(src.data && typeof src.data !== 'string' ? { data: new Uint8Array(src.data) } : {}),
  }).promise
}

// This function cannot be defined in `~/common/util.ts` because it makes
// cypress crash.
export const checkPdfCorrupt = async (
  src: GetPdfDocumentParams
): Promise<
  | { isCorrupted: false }
  | { isCorrupted: true; error: unknown; reason: 'PDF_CORRUPTED' | 'PDF_PASSWORD_PROTECTED' }
> => {
  try {
    const doc = await getPdfDocument(src)
    // Assert that the doc has at least one page and it's accessible
    await doc.getPage(1)
    return { isCorrupted: false }
  } catch (e) {
    if (e instanceof Error && e.message.toLowerCase().includes('no password'))
      return { isCorrupted: true, error: e, reason: 'PDF_PASSWORD_PROTECTED' }

    return { isCorrupted: true, error: e, reason: 'PDF_CORRUPTED' }
  }
}
