import { Badge, Text } from '@mantine/core'
import React from 'react'
import { theme } from '~/client/lib/theme'
import type { ZRelationTypeValues } from '~/common/schema/relation'
import { typeAugmentedRelationMap } from '~/common/schema/relation'

interface CommonDisplayProps {
  isInactive?: boolean
}

interface TypeDisplayProps extends CommonDisplayProps {
  compact?: boolean
}

const BaseBadge: React.FC<{ isInactive?: boolean; badgeText: string }> = ({
  isInactive,
  badgeText,
}) => <Badge color={isInactive ? 'inactive' : 'primary'}>{badgeText}</Badge>
interface RelationTypeBadgeDisplayProps extends TypeDisplayProps {
  type: ZRelationTypeValues
}

interface RelationBadgeDisplayProps extends CommonDisplayProps {
  value: string | null | undefined
}

export const RelationBadgeDisplay: React.FC<RelationBadgeDisplayProps> = ({
  value,
  isInactive,
}) => {
  // Not using if (!value) because 0 is considered falsy
  if (value === null || value === undefined)
    return (
      <Text c={theme.colors.gray[5]} ta='center'>
        &mdash;
      </Text>
    )

  return <BaseBadge badgeText={value} isInactive={isInactive} />
}

export const RelationTypeBadgeDisplay: React.FC<RelationTypeBadgeDisplayProps> = ({
  type,
  compact,
  isInactive,
}) => (
  <RelationBadgeDisplay
    value={compact ? type : typeAugmentedRelationMap[type].display}
    isInactive={isInactive}
  />
)

export const OrgInfoTypeBadgeDisplay: React.FC<TypeDisplayProps> = ({ compact, isInactive }) => (
  <BaseBadge badgeText={compact ? 'CORP' : 'Organizational Info'} isInactive={isInactive} />
)
