import type { CryptId } from '@cryptid-module'
import { Button, Center, Loader, Paper, Skeleton, Stack, Table, Title } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import dynamic from 'next/dynamic'
import Router from 'next/router'
import React from 'react'
import { DocCard } from '~/client/components/doc-card'
import { DocComponentsLayout } from '~/client/components/doc-view'
import { NotFound } from '~/client/components/not-found'
import { AssociatedRelationsTable } from '~/client/components/relation/detail/associated-relations-table'
import { DetailHeaderLayout, DetailLayout } from '~/client/components/relation/detail/layout'
import { DocComponents } from '~/client/components/relation/doc-button'
import { CapTableDetails } from '~/client/components/relation/equity'
import { RedFlagSkeleton, RedFlagsSection } from '~/client/components/relation/red-flag'
import {
  useDeleteRelation,
  useDocSearchModal,
  useRelationSetLink,
} from '~/client/components/relation/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { MetadataRow } from '~/client/components/util/metadata'
import { hooks, useCorpCryptId, useCurrentCorpAuth } from '~/client/lib/hooks'
import { optimisticMutationOptions } from '~/client/lib/hooks/optimistic-update'
import { theme } from '~/client/lib/theme'
import type { EnhancedRelation, RedFlagInfo } from '~/common/enhance'
import { enhanceRelation, getRelationRedFlagInfo } from '~/common/enhance'
import { navDropRoutePathMap, relationTypeRouteMap } from '~/common/route/top-route'
import type { ZUpdateRelationPartial } from '~/common/schema/relation'
import { type ZAugmentedRelation, ZEquityType, ZPersonnelType } from '~/common/schema/relation'
import { MetadataEditableRelation } from './editable-relation'
import { MetadataTable } from './metadata'

const DocsLayout = dynamic(() =>
  import('~/client/components/util/doc').then((mod) => mod.DocsLayout)
)

const SubDetail: React.FC<{
  enhancedRelation: EnhancedRelation
  redFlagInfo: RedFlagInfo | undefined
  update: (cryptId: CryptId, data: ZUpdateRelationPartial) => Promise<unknown>
}> = ({ enhancedRelation, redFlagInfo, update }) => {
  const { data: auth } = useCurrentCorpAuth()
  const { mkCurrentCorpRoute } = useCorpCryptId()
  const setLink = useRelationSetLink(enhancedRelation)
  const { modal, openSearchModal } = useDocSearchModal(enhancedRelation, setLink)

  const updateWithIdAndType = (updateData: ZUpdateRelationPartial) =>
    update(enhancedRelation.cryptId, { type: enhancedRelation.type, ...updateData })

  const { deleteRelationPrompt, isLoading } = useDeleteRelation(() => {
    // After delete, replace route because doc detail route no longer exists
    return Router.replace(
      mkCurrentCorpRoute(relationTypeRouteMap[enhancedRelation.type].collection)
    )
  })

  const withEditableRelation =
    enhancedRelation.type === 'PREFERRED' || enhancedRelation.type === 'OPTION'
  const withAssociatedRelations =
    ZEquityType.isType(enhancedRelation.type) || ZPersonnelType.isType(enhancedRelation.type)
  const withRelations = withEditableRelation || withAssociatedRelations

  return (
    <>
      <DetailLayout
        redFlagInfo={redFlagInfo}
        withRelations={withRelations}
        data={enhancedRelation}
        metadataComponent={
          <>
            <MetadataTable data={enhancedRelation} update={updateWithIdAndType} />

            {enhancedRelation.type === 'FUNDRAISING' && (
              <Paper shadow='xs' p='lg'>
                <Title order={3} mb='md'>
                  Equity Holders
                </Title>
                <CapTableDetails
                  noAddNew
                  relationTypes={['PREFERRED']}
                  noDataMsg='No equity holders for this fundraising round'
                  relationFilters={{ fundraisingCryptId: enhancedRelation.cryptId }}
                />
              </Paper>
            )}
            <Button
              size='md'
              m='12px'
              color='danger'
              data-testid='delete-relation-button'
              onClick={() => deleteRelationPrompt(enhancedRelation.cryptId, enhancedRelation.type)}
              disabled={isLoading || auth?.level === 'investor'}
              leftSection={isLoading ? <Loader /> : <IconTrash />}
              style={{
                alignSelf: 'start',
              }}
            >
              Delete Relation
            </Button>
          </>
        }
        documentsComponent={
          <DocComponents
            redFlagInfo={redFlagInfo}
            data={enhancedRelation}
            setLink={setLink}
            openSearchModal={openSearchModal}
          />
        }
        redFlagsComponent={
          redFlagInfo && (
            <RedFlagsSection
              data={enhancedRelation}
              redFlagInfo={redFlagInfo}
              openSearchModal={openSearchModal}
              autofill={enhancedRelation.autofill}
            />
          )
        }
        relationsComponent={
          <Stack gap='xl'>
            {withEditableRelation && (
              <Stack gap='sm'>
                <Title order={3}>Linked Relation</Title>
                <MetadataEditableRelation data={enhancedRelation} update={updateWithIdAndType} />
              </Stack>
            )}
            {withAssociatedRelations && (
              <Stack gap='sm'>
                <Title order={3}>Associated Relations</Title>
                <AssociatedRelationsTable relation={enhancedRelation} />
              </Stack>
            )}
          </Stack>
        }
      />
      {modal}
    </>
  )
}

/**
 * Skeleton of the size of a Metadata Table with a defined amount of `rows`.
 * Each detail page may have a different amount of metadata rows.
 */
const MetadataSkeleton: React.FC<{ rows: number }> = ({ rows }) => (
  <Skeleton visible maw={theme.other.widths.md}>
    <Table striped>
      <Table.Tbody>
        {Array.from({ length: rows }).map((_, key) => (
          <MetadataRow
            key={key}
            displayProps={{
              display: 'XXXXX',
              schema: 'string',
              initialValue: { value: 'XXXXX', type: 'edited' },
              supportedTypes: [],
            }}
          />
        ))}
      </Table.Tbody>
    </Table>
  </Skeleton>
)

interface DetailSkeletonProps {
  metadataRows: number
  docsAmount: number
}

/**
 * The skeleton for the relation detail page.
 */
export const DetailSkeleton: React.FC<DetailSkeletonProps> = ({ metadataRows, docsAmount }) => {
  return (
    <DetailLayout
      redFlagInfo={undefined}
      data={undefined}
      testId='relation-detail-skeleton'
      metadataComponent={<MetadataSkeleton rows={metadataRows} />}
      documentsComponent={
        <DocComponentsLayout>
          <DocsLayout>
            {Array.from({ length: docsAmount }).map((_, key) => (
              <DocCard key={key} isLoading />
            ))}
          </DocsLayout>
        </DocComponentsLayout>
      }
      redFlagsComponent={<RedFlagSkeleton />}
    />
  )
}

const DetailSkeletonWithHeader: React.FC<DetailSkeletonProps> = (props) => {
  return (
    <DetailHeaderLayout isSkeleton title='X.X Skeleton title' name='Skeleton name' backLink=''>
      <DetailSkeleton {...props} />
    </DetailHeaderLayout>
  )
}

export const DetailPage: React.FC<{ cryptId: CryptId }> = ({ cryptId }) => {
  const { data: auth } = useCurrentCorpAuth()
  const { corpCryptId, mkCurrentCorpRoute } = useCorpCryptId()

  const utils = hooks.trpc().useContext()

  const relationQueryResult = hooks
    .trpc()
    .relations.byCryptIds.useQueryWithCorp({ cryptIds: [cryptId] })

  const redFlagQueryEnabled = !!auth && auth.level !== 'investor'
  const redFlagsQueryResult = hooks
    .trpc()
    .redFlags.get.byPrimaryIds.useQueryWithCorp(
      { primaryCryptIds: [cryptId] },
      { enabled: redFlagQueryEnabled }
    )

  const queryResult = {
    isFetching: relationQueryResult.isFetching || redFlagsQueryResult.isFetching,
    isLoading:
      relationQueryResult.isLoading || (redFlagQueryEnabled && redFlagsQueryResult.isLoading),
    isError: relationQueryResult.isError || redFlagsQueryResult.isError,
    error: relationQueryResult.error || redFlagsQueryResult.error,
  }

  const queryParams = { corpCryptId, cryptIds: [cryptId] }
  const update = hooks.trpc().relation.update.useMutationWithCorp({
    ...optimisticMutationOptions(utils.relations.byCryptIds, queryParams, (input, oldData) => {
      if (!oldData) {
        return
      }
      return [{ ...oldData[0], ...input.data }] as ZAugmentedRelation[]
    }),
  })

  const relation = relationQueryResult.data?.[0]

  const updateRelation = (relationCryptId: CryptId, _data: ZUpdateRelationPartial) =>
    update.mutateAsync({ cryptId: relationCryptId, data: _data })

  return (
    <LoadingErrorComp
      queryResult={queryResult}
      skeleton={<DetailSkeletonWithHeader metadataRows={3} docsAmount={5} />}
    >
      {(() => {
        if (!relation) {
          return (
            <Center h='60vh'>
              <NotFound type='relation' />
            </Center>
          )
        }

        const route = relationTypeRouteMap[relation.type]
        const relationDisplayStr = navDropRoutePathMap[route.collection].display
        const enhancedRelation = enhanceRelation(relation)
        const redFlagInfo =
          redFlagsQueryResult.data &&
          getRelationRedFlagInfo(enhancedRelation, redFlagsQueryResult.data)

        const linkWithHash = (collection: typeof route.collection) => {
          const relationSummaryLink = mkCurrentCorpRoute(route.collection)
          switch (collection) {
            case 'cap-table':
              return `${relationSummaryLink}?tab=details`
            default:
              return relationSummaryLink
          }
        }

        return (
          <div data-testid='relation-detail-layout'>
            <DetailHeaderLayout
              name={relationDisplayStr}
              backLink={linkWithHash(route.collection)}
              title={enhancedRelation.display}
            >
              <SubDetail
                redFlagInfo={redFlagInfo}
                enhancedRelation={enhancedRelation}
                update={updateRelation}
              />
            </DetailHeaderLayout>
          </div>
        )
      })()}
    </LoadingErrorComp>
  )
}
