import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'
import { partition, sortBy } from 'underscore'
import type { XLSX$Utils } from 'xlsx'
import { hooks } from '~/client/lib/hooks/dependency-injection/interface'
import { exhaustPaginated } from '~/common/exhausted-query'
import { redFlagCategoryTypes } from '~/common/red-flags'
import type { ZAugmentedRedFlag, ZRedFlagCategory } from '~/common/schema/red-flag'
import { objectEntriesTypesafe } from '~/common/util'

export const redFlagCategoryDisplayMap: Record<ZRedFlagCategory, string> = {
  orgInfo: 'Corporate',
  personnel: 'Personnel',
  equity: 'Equity',
  custom: 'Custom',
}

const getCategoryFromFlag = ({ type }: ZAugmentedRedFlag) => {
  const found = objectEntriesTypesafe(redFlagCategoryTypes)
    .filter(([_, flags]) => flags.includes(type))
    .map(([category, _]) => category)[0]
  if (!found) throw new Error("Red flag doesn't belong to any category")
  return found
}

const mkSheet = (utils: XLSX$Utils, flags: ZAugmentedRedFlag[]) => {
  const columns = ['Category', 'Red Flag', 'Status']

  const sortedAlphaFlags = sortBy(flags, 'display')
  const sortedCategoriesFlags = sortBy(sortedAlphaFlags, (flag) => {
    switch (getCategoryFromFlag(flag)) {
      case 'orgInfo':
        return 0
      case 'personnel':
        return 1
      case 'equity':
        return 2
      case 'custom':
        return 3
    }
  })

  const data = sortedCategoriesFlags.map((flag): [string, string, string] => {
    const category = getCategoryFromFlag(flag)
    return [
      redFlagCategoryDisplayMap[category],
      flag.display,
      flag.dismissed ? `Dismissed` : 'Active',
    ]
  })

  const sheet = utils.aoa_to_sheet([columns, ...data])
  utils.sheet_add_aoa(sheet, [columns], { origin: 'A1' })

  // Adjust the column width to make it looking good at first open
  sheet['!cols'] = data
    .reduce<number[]>(
      (prev, current) => current.map((value, i) => Math.max(value.toString().length, prev[i] ?? 0)),
      columns.map((c) => c.toString().length)
    )
    .map((w) => ({ wch: w }))

  return sheet
}

const mkRedFlagsXlsx = async (flags: ZAugmentedRedFlag[]) => {
  const XLSX = await import('xlsx')

  const [activeFlags, dismissedFlags] = partition(flags, (flag) => !flag.dismissed)
  const activeSheet = mkSheet(XLSX.utils, activeFlags)
  const dismissedSheet = mkSheet(XLSX.utils, dismissedFlags)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, activeSheet, 'Active Red Flags')
  XLSX.utils.book_append_sheet(workbook, dismissedSheet, 'Dismissed Red Flags')

  return { workbook, XLSX }
}

export const useDownloadRedFlagsXlsx = (): UseMutationResult<void, unknown, void> => {
  const fetchRedFlags = hooks.trpc().redFlags.get.all.useFetchWithCorp()
  const { data: corpData } = hooks.useCurrentCorp()

  return useMutation({
    mutationFn: async () => {
      const flags = await exhaustPaginated(fetchRedFlags, {
        limit: Infinity,
      })

      const { XLSX, workbook } = await mkRedFlagsXlsx(flags)

      const date = new Date().toLocaleDateString('en-US').replaceAll('/', '-')
      const filename = `${[corpData?.name.value, 'Red Flags', date]
        .filter(Boolean)
        .join(' - ')}.xlsx`
      XLSX.writeFileXLSX(workbook, filename, { compression: true })
    },
    mutationKey: ['download-red-flags-xlsx'],
    meta: {
      skipInvalidate: true,
    },
  })
}
