import type { ObjectId } from 'mongodb'

type RedFlagComputeTypes =
  | 'CUSTOM'
  | 'CORP_REQUIRES_DOC'
  | 'RELATION_REQUIRES_DOC'
  | 'RELATION_METADATA'
  | 'RELATION_REQUIRES_RELATION'
  | 'EXTERNAL'
  | 'DOC'

export interface BaseRedFlagInstance {
  corpId: ObjectId
  primaryId: ObjectId
  active: true
}

export interface RedFlagInstance<T extends string> extends BaseRedFlagInstance {
  type: T
}

export class RedFlagRule<O, T extends string> {
  constructor(
    /**Red flag type string*/
    public type: T,
    /**Red flag rule compute type. Determines what records a rule should be
     * executed on */
    public computeType: RedFlagComputeTypes,
    /** Red flag display function. It takes the red flag
     * primary object as argument */
    public display: (
      /** Red flag primary object display */
      display: string
    ) => string,
    public shortDisplay: (
      /** Red flag primary object display */
      display: string
    ) => string,
    /** Function used to enforce a red flag rule on the primary object. It
     * returns a red flag instance if the primary object breaks the rule or null
     * if it doesn't */
    public enforce: (
      /** Red flag primary object */
      obj: O
    ) => RedFlagInstance<T> | null
  ) {}
}
