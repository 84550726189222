import { Table, Tabs } from '@mantine/core'
import React from 'react'
import {
  RelationHeaders,
  RelationTable,
  type SortRenderer,
} from '~/client/components/relation/base-table'
import { SortHeader } from '~/client/components/util/sort'
import { hooks } from '~/client/lib/hooks'
import { getDateCellValue } from '~/client/lib/relations-xlsx'
import type { EnhancedRelation, RedFlagInfo } from '~/common/enhance'
import { AugmentedMetadataPrice2 } from '~/common/schema'
import type { ZRelationFilters } from '~/common/schema/relation'
import { ZAugmentedEquity, ZAugmentedRelation, ZEquityType } from '~/common/schema/relation'
import { CapTableSummary } from './cap-table-summary'
import { CaptableRow } from './util'

export interface Shareholder {
  enhancedRelation: EnhancedRelation<ZAugmentedEquity>
  redFlagInfo: RedFlagInfo | undefined
}

interface CapTableDetailsProps {
  relationTypes?: typeof ZEquityType.options
  relationFilters?: ZRelationFilters
  noDataMsg?: string
  noAddNew?: boolean
}

export const CapTableDetails: React.FC<CapTableDetailsProps> = ({
  noDataMsg = 'No CapTable records found',
  relationTypes = ZEquityType.options,
  relationFilters,
  noAddNew,
}) => {
  const renderHeaders: SortRenderer = (props) => (
    <RelationHeaders
      {...props}
      noAddNew={noAddNew}
      baseHeaderName={{
        name: 'Equity Holder',
        startDate: 'Issue Date',
        endDate: 'Termination Date',
      }}
      nameSortField='party.name'
      relationTypes={relationTypes}
      renderCustomHeaders={(headerProps) => (
        <>
          <SortHeader {...headerProps} sortField='type'>
            Class
          </SortHeader>
          <Table.Th>Fundraising Round</Table.Th>
          <Table.Th ta='end'>Shares</Table.Th>
          <Table.Th ta='end'>Investment</Table.Th>
        </>
      )}
    />
  )
  const renderRows = (shareholders: Shareholder[]) => (
    <>
      {shareholders.map((s) => {
        const key = s.enhancedRelation.cryptId.idStr

        switch (s.enhancedRelation.type) {
          case 'OPTION':
            return (
              <CaptableRow
                shareholder={s}
                linkedRelation={s.enhancedRelation.valuation}
                key={key}
              />
            )
          case 'PREFERRED':
            return (
              <CaptableRow
                shareholder={s}
                linkedRelation={s.enhancedRelation.fundraising}
                key={key}
              />
            )
          default:
            return <CaptableRow shareholder={s} key={key} />
        }
      })}
    </>
  )

  return (
    <RelationTable
      noAddNew={noAddNew}
      relationTypes={relationTypes}
      renderRows={renderRows}
      relationFilters={relationFilters}
      renderHeaders={renderHeaders}
      noDataMsg={noDataMsg}
      downloadOptions={{
        sheetTitle: ZAugmentedEquity.display,
        headers: [
          'Name',
          'Email',
          'Issue Date',
          'Termination Date',
          'Class',
          'Shares',
          'Investment',
          'Number of Documents',
        ],
        rowProvider: (r) => {
          const { name, email } = ZAugmentedRelation.partyNameEmailWithIndex(r)
          return [
            name,
            email,
            getDateCellValue(r.startDate?.value),
            getDateCellValue(r.endDate?.value),
            r.type,
            'shares' in r ? r.shares?.value?.toLocaleString() ?? '' : '',
            'investment' in r && r.investment
              ? AugmentedMetadataPrice2.display(r.investment.value)
              : '',
            r.docs.length,
          ]
        },
      }}
    />
  )
}

export const CapTableComp: React.FC = () => {
  const navigationTabs = hooks.useNavigationTabs({
    tabs: ['summary', 'details'],
    defaultTab: 'details',
  })

  return (
    <Tabs {...navigationTabs} keepMounted={false}>
      <Tabs.List>
        <Tabs.Tab data-testid='cap-table-summary-btn' value='summary'>
          Summary
        </Tabs.Tab>
        <Tabs.Tab value='details'>Details</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value='summary'>
        <CapTableSummary />
      </Tabs.Panel>

      <Tabs.Panel value='details'>
        <CapTableDetails />
      </Tabs.Panel>
    </Tabs>
  )
}
