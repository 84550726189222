import type { Mutation, Query } from '@tanstack/react-query'
import { WrongAuthProviderError } from '~/client/lib/wrong-auth-provider-error'
import { analytics } from './analytics'
import { handleError } from './handle-error'
import type { ExtraDataFromMutation, ExtraDataFromQuery } from './util'
import {
  extractFullDataToParseProperty,
  getExtraDataFromMutation,
  getExtraDataFromQuery,
} from './util'

interface QueryErrorExtra extends ExtraDataFromQuery {
  success: false
}
interface MutationSuccessExtra extends ExtraDataFromMutation {
  success: true
}
interface MutationErrorExtra extends ExtraDataFromMutation {
  success: false
}

export interface QueryError {
  type: 'Query'
  error: unknown
  retry?: () => Promise<unknown> | unknown
  extra: QueryErrorExtra
  addSentryIdToErrorState: (id: string) => void
}

export interface MutationSuccess {
  type: 'Mutation'
  result: unknown
  extra: MutationSuccessExtra
}

export interface MutationError {
  type: 'Mutation'
  error: unknown
  retry?: () => Promise<unknown> | unknown
  extra: MutationErrorExtra
  addSentryIdToErrorState: (id: string) => void
}

export type RequestError = QueryError | MutationError

export type EventType =
  | 'DOC_DOWNLOAD'
  | 'COPY_METADATA'
  | 'COPY'
  | 'DATA_ROOM_DOWNLOAD'
  | 'WINDOW_FOCUS'
  | 'WINDOW_UNFOCUS'
  | 'SINGLE_FILE_UPLOAD'
  | 'MULTI_FILE_UPLOAD'
  | 'DUPLICATE_DOC_UPLOAD'
  | 'PERMISSION_USER_ADDED'
  | 'BETA_INTEGRATION_REQUEST'
export interface MonitoringEvent {
  type: 'Event'
  extra: { success: true; eventType: EventType }
}

export type MonitoringData = MutationError | MutationSuccess | QueryError | MonitoringEvent

const addSentryIdToErrorState = (
  request: Query | Mutation<unknown, unknown, unknown, unknown>,
  id: string
) =>
  request.setState({
    ...request.state,
    error: {
      // We use object because react-query types aren't good enough to recognize
      // that these are valid
      ...(request.state.error as object),
      sentryEventId: id,
    },
    // eslint-disable-next-line custom-rules/prefer-extends-to-type-intersection
  } as Query['state'] & Mutation<unknown, unknown, unknown, unknown>['state'])

export const handleClientMutationError = (
  error: unknown,
  _variables: unknown,
  _context: unknown,
  mutation: Mutation<unknown, unknown, unknown, unknown>
): void =>
  handleError(
    {
      type: 'Mutation',
      error,
      retry: !mutation.meta?.preventUserRetry ? () => mutation.execute() : undefined,
      extra: {
        ...getExtraDataFromMutation(mutation),
        ...extractFullDataToParseProperty(error),
        ...(error instanceof WrongAuthProviderError ? error.data : {}),
        success: false,
      },
      addSentryIdToErrorState: (id: string) => addSentryIdToErrorState(mutation, id),
    },
    {
      notifyUser: !mutation.meta?.noErrorNotification,
      sendToAnalytics: true,
    }
  )

export const handleClientQueryError = (error: unknown, query: Query): void =>
  handleError(
    {
      type: 'Query',
      error,
      retry: () => query.fetch(),
      extra: {
        ...getExtraDataFromQuery(query),
        ...extractFullDataToParseProperty(error),
        success: false,
      },
      addSentryIdToErrorState: (id: string) => addSentryIdToErrorState(query, id),
    },
    {
      notifyUser: false,
      sendToAnalytics: false,
    }
  )

export const handleClientMutationSuccess = (
  _result: unknown,
  _variables: unknown,
  _context: unknown,
  mutation: Mutation<unknown, unknown, unknown, unknown>
): void => {
  analytics.track({
    type: 'Mutation',
    extra: { ...getExtraDataFromMutation(mutation), success: true },
  })
}
