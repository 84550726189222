import { Group } from '@mantine/core'
import React from 'react'
import { AddIntegrationCard } from '~/client/components/integration/'
import { StepContainer } from '~/client/components/onboarding-modal/util'

export const ConnectIntegrationsStep: React.FC = () => {
  const [clickedIntegrationAdd, setClickedIntegrationAdd] = React.useState(false)
  return (
    <StepContainer
      title='Connect to eSignature'
      subtitle='Automatically add documents to stay organized with zero lift'
      stepCompleted={clickedIntegrationAdd}
      dataTestId='onboarding-connect-integrations-step'
    >
      <Group>
        <AddIntegrationCard
          type='docusign'
          isOnboardingIntegrations
          onButtonClick={() => setClickedIntegrationAdd(true)}
        />
        <AddIntegrationCard
          type='adobeSign'
          isOnboardingIntegrations
          onButtonClick={() => setClickedIntegrationAdd(true)}
        />
      </Group>
    </StepContainer>
  )
}
