import type { ComboboxData, ComboboxItem, OptionsFilter } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import React from 'react'
import { groupBy, omit, partition, sortBy } from 'underscore'
import { mkSelectFilter } from '~/client/components/util/select-filter'
import type { SearchQueryObj } from '~/client/lib/query-params'
import type { ZDocFilters } from '~/common/schema'
import { AugmentedMetadata, ZAugmentedDoc, type ZDocType, docTypeStr } from '~/common/schema'
import type { ZRelationQueryFilters } from '~/common/schema/relation'
import {
  ZAugmentedRelation,
  type ZRelationTypeValues,
  typeAugmentedRelationMap,
} from '~/common/schema/relation'
import { exhaustivenessSafetyCheck, objectEntriesTypesafe } from '~/common/util'
import type { ObjectEntriesTypesafe } from '~/common/util'

interface SearchItem extends ComboboxItem {
  group: string
  search: string
}

const TYPE_PREFIX = ':type:'

export const mkTypeOptionValue = (value: string): string => `${TYPE_PREFIX}${value}`

export const isTypeOption = (value: string): boolean => value.startsWith(TYPE_PREFIX)
export const removeTypeOptionPrefix = (value: string): string => value.replace(TYPE_PREFIX, '') // Will replace the first occurrence only

export const splitTypeAndTextQueries = (
  queryObj: SearchQueryObj
): { texts: string[]; docFilters: ZDocFilters; relationFilters: ZRelationQueryFilters } => {
  const [types, texts] = partition(queryObj.queries, isTypeOption)

  const typesWithoutPrefix = types.map(removeTypeOptionPrefix)
  const docTypes = typesWithoutPrefix.filter(ZAugmentedDoc.isType)
  const relationTypes = typesWithoutPrefix.filter(ZAugmentedRelation.isType)

  return {
    texts,
    docFilters: { ...(queryObj.docFilters ?? {}), type: docTypes },
    relationFilters: { ...(queryObj.relationFilters ?? {}), type: relationTypes },
  }
}

const docType2SearchItem = (type: ZDocType): SearchItem => {
  const label = `Doc ${docTypeStr(type)}`
  return {
    value: mkTypeOptionValue(type),
    label,
    group: 'Document Type',
    search: `${type} ${label} ${docTypeStr(type, true)}`.toLowerCase(),
    disabled: false,
  }
}

const relationType2SearchItem = (type: ZRelationTypeValues): SearchItem => {
  const label = `Relation ${typeAugmentedRelationMap[type].display}`
  return {
    value: mkTypeOptionValue(type),
    label,
    group: 'Relation Type',
    search: `${type} ${label}`.toLowerCase(),
    disabled: false,
  }
}

const docRelation2SearchItem = (type: ZDocType | ZRelationTypeValues): SearchItem => {
  if (ZAugmentedDoc.isType(type)) {
    return docType2SearchItem(type)
  }
  return relationType2SearchItem(type)
}

const allDocItems: SearchItem[] = ZAugmentedDoc.types.map(docType2SearchItem)
const allRelationItems: SearchItem[] = ZAugmentedRelation.types.map(relationType2SearchItem)

const allItemValueSet = new Set([...allDocItems, ...allRelationItems].map((item) => item.value))

const itemMap: Map<string, SearchItem> = new Map()
allDocItems.forEach((item) => itemMap.set(item.value, item))
allRelationItems.forEach((item) => itemMap.set(item.value, item))

const customValueItemGroup = 'Custom Values'
const customValueItem = (text: string): SearchItem => ({
  value: text,
  label: text,
  search: text, // needed for search to work
  group: customValueItemGroup,
})
const isCustomValueItem = (item: SearchItem): boolean => {
  return item.group === customValueItemGroup
}

export interface UseSearchOptions {
  data: ComboboxData
  filter: OptionsFilter
  create: (text: string) => SearchItem
  onChange: (values: string[]) => void
}

export interface ItemWithSearch extends ComboboxItem {
  search?: string
}
export const stringComparisonOptionsFilterFn = (search: string, item: ItemWithSearch): boolean => {
  const parsedSearch = search.trim().toLowerCase()
  return item.search && item.search.length
    ? item.search.toLowerCase().includes(parsedSearch)
    : item.label.toLowerCase().includes(parsedSearch)
}

/**
 * Available search options structured for use with a Mantine MultiSelect search bar
 * @param queries from URL
 * @param allowedTypes
 * @param filterFn
 * @returns
 */
export const useSearchOptions = (
  queries: string[],
  allowedTypes: (ZDocType | ZRelationTypeValues)[],
  filterFn: (search: string, item: ItemWithSearch) => boolean
): UseSearchOptions => {
  const queryCustomValues = queries.filter((value) => !allItemValueSet.has(value))
  const queryCustomValueItems = queryCustomValues.map((value) => customValueItem(value))
  const allowedItems: SearchItem[] = allowedTypes.map(docRelation2SearchItem)
  const [listData, handlers] = useListState<SearchItem>([...allowedItems, ...queryCustomValueItems])

  const data = React.useMemo(
    () =>
      Object.entries(groupBy(listData, 'group')).map(([group, items]) => ({
        group,
        items: sortBy(
          items.map((item) => omit(item, 'group')),
          'label'
        ),
      })),
    [listData]
  )
  const filter = React.useMemo(() => mkSelectFilter(filterFn), [filterFn])

  const create = React.useCallback(
    (text: string) => {
      const obj = customValueItem(text)
      handlers.append(obj)
      return obj
    },
    [handlers]
  )

  const onChange = React.useCallback(
    (values: string[]) => {
      const indexesToRemove = listData
        .map((item, index) =>
          isCustomValueItem(item) && !values.includes(item.value) ? index : undefined
        )
        .filter(Boolean)
      handlers.remove(...indexesToRemove)
    },
    [handlers, listData]
  )

  return {
    data,
    filter,
    create,
    onChange,
  }
}

export const getMetadata = (value: object): AugmentedMetadata | undefined => {
  const parsed = AugmentedMetadata.safeParse(value)
  return parsed.success ? parsed.data : undefined
}

export type FilterEntries =
  | ObjectEntriesTypesafe<ZDocFilters>
  | ObjectEntriesTypesafe<ZRelationQueryFilters>

const countFilters = (filters?: ZDocFilters | ZRelationQueryFilters) => {
  if (!filters) return 0
  // eslint-disable-next-line custom-rules/no-bad-casting-in-declaration
  const entries = objectEntriesTypesafe(filters) as FilterEntries

  return entries
    .map((entry) => {
      switch (entry[0]) {
        case 'endDate':
        case 'startDate':
        case 'party':
        case 'title':
        case 'date':
          return entry[1]
        case 'type':
          return (entry[1] ?? []).length
        default:
          return exhaustivenessSafetyCheck(entry[0])
      }
    })
    .filter(Boolean).length
}

export const countTotalFilters = (queryObj: SearchQueryObj): number => {
  const filters = splitTypeAndTextQueries(queryObj)

  return countFilters(filters.docFilters) + countFilters(filters.relationFilters)
}
