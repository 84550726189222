import { Table } from '@mantine/core'
import React from 'react'
import type { SortRenderer } from '~/client/components/relation/base-table'
import {
  RelationHeaders,
  RelationRow,
  RelationTable,
} from '~/client/components/relation/base-table'
import { RelationPriceDisplay } from '~/client/components/relation/display'
import { getDateCellValue } from '~/client/lib/relations-xlsx'
import type { EnhancedRelation, RedFlagInfo } from '~/common/enhance'
import { AugmentedMetadataPrice4 } from '~/common/schema'
import type { ZAugmentedValuation } from '~/common/schema/relation'

export const ValuationComp: React.FC = () => {
  const renderHeaders: SortRenderer = (props) => (
    <RelationHeaders
      relationTypes={['VALUATION']}
      renderCustomHeaders={() => <Table.Th>Valuation</Table.Th>}
      {...props}
    />
  )
  const renderRows = (
    data: {
      enhancedRelation: EnhancedRelation<ZAugmentedValuation>
      redFlagInfo: RedFlagInfo | undefined
    }[]
  ) => (
    <>
      {data.map((valuationData) => {
        const price = valuationData.enhancedRelation.valuation?.value
        return (
          <RelationRow
            redFlagInfo={valuationData.redFlagInfo}
            item={valuationData.enhancedRelation}
            key={valuationData.enhancedRelation.cryptId.idStr}
          >
            <Table.Td>
              <RelationPriceDisplay schema={AugmentedMetadataPrice4} value={price} />
            </Table.Td>
          </RelationRow>
        )
      })}
    </>
  )

  return (
    <RelationTable
      relationTypes={['VALUATION']}
      renderHeaders={renderHeaders}
      renderRows={renderRows}
      noDataMsg='No Valuation records found'
      downloadOptions={{
        sheetTitle: '409A Valuation',
        headers: ['Name', 'Start Date', 'End Date', 'Valuation', 'Number of Documents'],
        rowProvider: (r) => [
          r.display,
          getDateCellValue(r.startDate?.value),
          getDateCellValue(r.endDate?.value),
          AugmentedMetadataPrice4.display(r.valuation?.value) ?? '',
          r.docs.length,
        ],
      }}
    />
  )
}
