import a from 'indefinite'
import type { WithId } from 'mongodb'
import type { ZDocType } from '~/common/schema'
import { docTypeStr } from '~/common/schema/type-map'
import type { ZMongoCorp } from '~/server/mongo/schema/corp'
import { type RedFlagInstance, RedFlagRule } from './base'

class CorpDocRedFlagRule<D extends ZDocType, T extends `CORP_REQUIRES_${D}`> extends RedFlagRule<
  WithId<ZMongoCorp> & { docTypes: ZDocType[] },
  T
> {
  constructor(
    public type: T,
    public docType: D
  ) {
    super(
      type,
      'CORP_REQUIRES_DOC',
      (corpDisplayString) => {
        const docStr = docTypeStr(docType)
        return `${corpDisplayString} is missing ${a(docStr, { caseInsensitive: true })}`
      },
      (corpDisplayString) => {
        const docStr = docTypeStr(docType, true)
        return `${corpDisplayString} requires ${docStr}`
      },
      (corp) => {
        const hasRequiredType = corp.docTypes.some((dType) => dType === docType)

        if (!hasRequiredType)
          return {
            type,
            corpId: corp._id,
            primaryId: corp._id,
            active: true,
          }

        return null
      }
    )
  }
}

export const corpRedFlagRules = [
  new CorpDocRedFlagRule('CORP_REQUIRES_ARTICLES_OF_INCORPORATION', 'ARTICLES_OF_INCORPORATION'),
  new CorpDocRedFlagRule('CORP_REQUIRES_IRS_EIN_ASSIGNMENT_LETTER', 'IRS_EIN_ASSIGNMENT_LETTER'),
  new CorpDocRedFlagRule('CORP_REQUIRES_CORPORATE_BYLAWS', 'CORPORATE_BYLAWS'),
  new CorpDocRedFlagRule('CORP_REQUIRES_BOARD_CONSENT_AND_MINUTES', 'BOARD_CONSENT_AND_MINUTES'),
  new CorpDocRedFlagRule('CORP_REQUIRES_STOCKHOLDER_CONSENT', 'STOCKHOLDER_CONSENT'),
  new CorpDocRedFlagRule('CORP_REQUIRES_ANNUAL_REPORT', 'ANNUAL_REPORT'),
  new CorpDocRedFlagRule('CORP_REQUIRES_INCORPORATOR_CONSENT', 'INCORPORATOR_CONSENT'),
] as const

export const corpRedFlagTypes = corpRedFlagRules.map((rule) => rule.type)

export type CorpRedFlagType = (typeof corpRedFlagTypes)[number]

export interface CorpRedFlagInstance extends RedFlagInstance<CorpRedFlagType> {}
