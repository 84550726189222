import { Group, Space, Table } from '@mantine/core'
import React from 'react'
import { RelationRow } from '~/client/components/relation/base-table'
import {
  RelationBadgeDisplay,
  RelationMiniDocDisplay,
  RelationPartyDisplay,
  RelationPriceDisplay,
  RelationTypeBadgeDisplay,
} from '~/client/components/relation/display'
import type { Shareholder } from '~/client/components/relation/equity/cap-table'
import type { EnhancedRelation } from '~/common/enhance'
import { AugmentedMetadataNumber, AugmentedMetadataPrice2 } from '~/common/schema'
import type {
  MinimalAugmentedRelation,
  ZAugmentedEquity,
  ZAugmentedPreferred,
} from '~/common/schema/relation'

export const IndentedText: React.FC = ({ children }) => (
  <Group gap={0} wrap='nowrap'>
    <Space w='md' />
    {children}
  </Group>
)

type CaptableCellsProps = EnhancedRelation<ZAugmentedEquity> & {
  shares?: AugmentedMetadataNumber
  investment?: AugmentedMetadataPrice2
  fundraising?: ZAugmentedPreferred['fundraising']
}

export const CaptableCells: React.FC<CaptableCellsProps> = ({
  isActive,
  type,
  shares,
  investment,
  fundraising,
}) => {
  return (
    <>
      <Table.Td>
        <RelationTypeBadgeDisplay type={type} isInactive={!isActive} compact />
      </Table.Td>
      <Table.Td>
        <RelationBadgeDisplay value={fundraising?.round?.value} isInactive={!isActive} />
      </Table.Td>
      <Table.Td>
        <RelationPriceDisplay schema={AugmentedMetadataNumber} value={shares?.value} />
      </Table.Td>
      <Table.Td>
        <RelationPriceDisplay schema={AugmentedMetadataPrice2} value={investment?.value} />
      </Table.Td>
    </>
  )
}

interface CaptableRowProps {
  shareholder: Shareholder
  linkedRelation?: MinimalAugmentedRelation
}

export const CaptableRow: React.FC<CaptableRowProps> = ({ shareholder, linkedRelation }) => {
  return (
    <RelationRow
      redFlagInfo={shareholder.redFlagInfo}
      item={shareholder.enhancedRelation}
      nameComp={<RelationPartyDisplay relation={shareholder.enhancedRelation} />}
      docsComp={
        linkedRelation && (
          <RelationMiniDocDisplay
            redFlagInfo={shareholder.redFlagInfo}
            linkedRelation={linkedRelation}
            relation={shareholder.enhancedRelation}
          />
        )
      }
    >
      <CaptableCells {...shareholder.enhancedRelation} />
    </RelationRow>
  )
}
