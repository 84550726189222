import {
  Button,
  Grid,
  Group,
  HoverCard,
  Loader,
  Menu,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core'
import {
  IconDownload,
  IconFileSpreadsheet,
  IconFileWord,
  IconInfoCircle,
} from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import { RedFlagCard } from '~/client/components/red-flags/red-flag-card'
import type { BaseRedFlagProps } from '~/client/components/red-flags/util'
import { NextLinkOpt } from '~/client/components/util'
import { zIndex } from '~/client/components/z-index'
import { hooks, useCorpCryptId } from '~/client/lib/hooks'
import { useDownloadRedFlagsXlsx } from '~/client/lib/hooks/red-flags-download'
import { theme } from '~/client/lib/theme'
import { enhanceCount } from '~/common/enhance'
import { ActiveRedFlagIcon } from '~/common/icons'
import { redFlagExplanation } from '~/common/red-flags'

export const RedFlagCards: React.FC<BaseRedFlagProps> = (props) => {
  const span = { base: 4, lg: 2, xl: 1 }

  return (
    <Grid maw={2000} columns={4} gutter={5}>
      <Grid.Col span={span}>
        <RedFlagCard category='orgInfo' {...props} />
      </Grid.Col>
      <Grid.Col span={span}>
        <RedFlagCard category='personnel' {...props} />
      </Grid.Col>
      <Grid.Col span={span}>
        <RedFlagCard category='equity' {...props} />
      </Grid.Col>
      <Grid.Col span={span}>
        <RedFlagCard category='custom' {...props} />
      </Grid.Col>
    </Grid>
  )
}

const DownloadButton: React.FC = () => {
  const downloadXlsxMutation = useDownloadRedFlagsXlsx()

  return (
    <Menu shadow='md'>
      <Menu.Target>
        <Button
          leftSection={
            <ThemeIcon color='primary'>
              <IconDownload />
            </ThemeIcon>
          }
        >
          Download
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={
            downloadXlsxMutation.isLoading ? (
              <Loader size='xs' mx={5} />
            ) : (
              <ThemeIcon>
                <IconFileSpreadsheet />
              </ThemeIcon>
            )
          }
          onClick={() => downloadXlsxMutation.mutate()}
          disabled={downloadXlsxMutation.isLoading}
          closeMenuOnClick={false}
        >
          Download as Excel
        </Menu.Item>
        <Menu.Item
          leftSection={
            <ThemeIcon>
              <IconFileWord />
            </ThemeIcon>
          }
          disabled
          closeMenuOnClick={false}
        >
          Download as Word
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export const RedFlagsView: React.FC<BaseRedFlagProps> = (props) => {
  const { mkCurrentCorpRoute } = useCorpCryptId()
  const { data } = hooks.trpc().docs.count.useQueryWithCorp({ types: ['PROCESSING'] })
  const hasProcessing = data && data.count > 0

  const activeQueryResult = hooks
    .trpc()
    .redFlags.get.all.useInfiniteQueryWithCorp({ dismissed: false, limit: 1 }, {})
  const activeCount = activeQueryResult.data?.pages[0]?.count

  return (
    <>
      <Group align='flex-end' justify='space-between'>
        <Group align='start'>
          <ThemeIcon color='urgent' size='lg'>
            <ActiveRedFlagIcon size={32} />
          </ThemeIcon>
          <Stack gap={0}>
            <Group>
              <HoverCard
                position='right'
                shadow='md'
                width={theme.other.widths.sm}
                zIndex={zIndex.popover}
                withArrow
              >
                <HoverCard.Target>
                  <Title order={3} c='urgent'>
                    Aerial found {enhanceCount(activeCount)} Red{' '}
                    {pluralize('Flags', activeCount?.count ?? 0)} <IconInfoCircle size={18} />
                  </Title>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text c='dimmed' size='sm'>
                    {redFlagExplanation}
                  </Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>
            {hasProcessing && (
              <Title order={5} c='gray'>
                Your red flags may be resolved by the {enhanceCount(data)} documents that Aerial is{' '}
                <NextLinkOpt href={mkCurrentCorpRoute('processing')}>still processing</NextLinkOpt>
              </Title>
            )}
          </Stack>
        </Group>
        <Button
          onClick={() => {
            props.openCustomRedFlagModal({ mode: 'new', props: { type: 'CUSTOM' } })
          }}
          data-testid='add-red-flag-btn'
        >
          Add Custom Red Flag
        </Button>
      </Group>
      <RedFlagCards {...props} />
    </>
  )
}

export const RedFlagsPage: React.FC<BaseRedFlagProps> = (props) => {
  return (
    <Stack gap='lg'>
      <RedFlagsView {...props} />

      <Group justify='flex-end' maw={2000}>
        <DownloadButton />
      </Group>
    </Stack>
  )
}
