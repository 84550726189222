import React from 'react'
import type { SortRenderer } from '~/client/components/relation/base-table'
import {
  RelationHeaders,
  RelationRow,
  RelationTable,
} from '~/client/components/relation/base-table'
import type { EnhancedRelation, RedFlagInfo } from '~/common/enhance'
import { ZAugmentedOptionPlan } from '~/common/schema/relation'

export const StockOptionPlanComp: React.FC = () => {
  const renderHeaders: SortRenderer = (props) => (
    <RelationHeaders relationTypes={['OPTIONPLAN']} {...props} />
  )
  const renderRows = (
    data: {
      enhancedRelation: EnhancedRelation<ZAugmentedOptionPlan>
      redFlagInfo: RedFlagInfo | undefined
    }[]
  ) => (
    <>
      {data.map((optionPlanData) => (
        <RelationRow
          redFlagInfo={optionPlanData.redFlagInfo}
          key={optionPlanData.enhancedRelation.cryptId.idStr}
          item={optionPlanData.enhancedRelation}
        />
      ))}
    </>
  )

  return (
    <RelationTable
      relationTypes={['OPTIONPLAN']}
      renderHeaders={renderHeaders}
      renderRows={renderRows}
      noDataMsg='No Equity Incentive Plan records found'
      downloadOptions={{
        sheetTitle: ZAugmentedOptionPlan.display,
      }}
    />
  )
}
