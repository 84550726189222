import { Accordion, Card, Group, Skeleton, Text, ThemeIcon } from '@mantine/core'
import { IconBuilding, IconChartPie, IconPencil, IconUsers } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import { RedFlagStack } from '~/client/components/red-flags/red-flag-stack'
import type { BaseRedFlagProps } from '~/client/components/red-flags/util'
import { LabelWithIndex } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { hooks } from '~/client/lib/hooks'
import { nextPageParamOpts } from '~/client/lib/hooks/query'
import { redFlagCategoryDisplayMap } from '~/client/lib/hooks/red-flags-download'
import { enhanceCount } from '~/common/enhance'
import type { ZNumberExceed } from '~/common/number-exceed'
import type { ZRedFlagCategory } from '~/common/schema/red-flag'

export const CardHeader: React.FC<{
  category: ZRedFlagCategory
  outstandingCount?: ZNumberExceed
}> = ({ category, outstandingCount }) => {
  const title = redFlagCategoryDisplayMap[category]
  const { icon, index } = React.useMemo(() => {
    switch (category) {
      case 'orgInfo':
        return { index: [1], icon: <IconBuilding /> }
      case 'personnel':
        return { index: [2], icon: <IconUsers /> }
      case 'equity':
        return { index: [3], icon: <IconChartPie /> }
      case 'custom':
        return { icon: <IconPencil /> }
    }
  }, [category])

  return (
    <Group mt={2}>
      <ThemeIcon size='sm'>{icon}</ThemeIcon>
      {index ? (
        <LabelWithIndex label={title} indexList={index} labelStyle={{ fw: 700 }} />
      ) : (
        <Text fw={700} size='md'>
          {title}
        </Text>
      )}
      {outstandingCount?.count && (
        <Text c='dimmed' size='xs' ml='auto'>
          {enhanceCount(outstandingCount)} outstanding red{' '}
          {pluralize('flag', outstandingCount.count)}
        </Text>
      )}
    </Group>
  )
}

interface RedFlagCardProps extends BaseRedFlagProps {
  category: ZRedFlagCategory
}

export const RedFlagCard: React.FC<RedFlagCardProps> = ({ category, ...props }) => {
  const [value, setValue] = React.useState<'active' | 'dismissed'>('active')

  const activeQueryResult = hooks
    .trpc()
    .redFlags.get.all.useInfiniteQueryWithCorp(
      { dismissed: false, limit: 10, category },
      { keepPreviousData: true, ...nextPageParamOpts() }
    )
  const activeCount = activeQueryResult.data?.pages[0]?.count

  const dismissedQueryResult = hooks
    .trpc()
    .redFlags.get.all.useInfiniteQueryWithCorp(
      { dismissed: true, limit: 10, category },
      { keepPreviousData: true, ...nextPageParamOpts() }
    )
  const dismissedCount = dismissedQueryResult.data?.pages[0]?.count

  // If the user re-flags the last dismissed flag,
  // go back to the active red flags view
  React.useEffect(() => {
    if (!dismissedCount) return
    if (value === 'dismissed' && dismissedCount.count === 0) setValue('active')
  }, [value, dismissedCount])

  return (
    <Card withBorder shadow='sm' radius='md' style={{ flex: 1 }} h='100%' p='sm'>
      <Card.Section inheritPadding withBorder py='xs'>
        <CardHeader category={category} outstandingCount={activeCount} />
      </Card.Section>

      <Card.Section inheritPadding my={3}>
        <Accordion
          value={value}
          onChange={(v) => setValue(v === 'dismissed' ? v : 'active')}
          mt='xs'
          styles={{
            content: {
              paddingLeft: '0',
              paddingRight: '0',
            },
            item: { border: 'none' },
          }}
        >
          <Accordion.Item value='active'>
            <Accordion.Panel>
              <RedFlagStack queryResult={activeQueryResult} {...props} />
            </Accordion.Panel>
          </Accordion.Item>

          <LoadingErrorComp
            queryResult={dismissedQueryResult}
            skeleton={<Skeleton w='100%' h={45} />}
          >
            {dismissedCount && dismissedCount.count !== 0 && (
              <Accordion.Item value='dismissed'>
                <Accordion.Control c='dimmed' data-testid='dismissed-flags-accordion'>
                  <Text size='sm'>
                    {value === 'dismissed'
                      ? `Hide ${enhanceCount(dismissedCount)} dismissed red ${pluralize(
                          'flag',
                          dismissedCount.count
                        )}`
                      : `Show ${enhanceCount(dismissedCount)} dismissed red ${pluralize(
                          'flag',
                          dismissedCount.count
                        )}`}
                  </Text>
                </Accordion.Control>

                <Accordion.Panel>
                  <RedFlagStack queryResult={dismissedQueryResult} {...props} />
                </Accordion.Panel>
              </Accordion.Item>
            )}
          </LoadingErrorComp>
        </Accordion>
      </Card.Section>
    </Card>
  )
}
