import { z } from 'zod'
import { ZDateFilter, ZStringFilter } from '~/common/schema/filters'
import { ZRelationTypeValues } from '~/common/schema/relation/augmented'
import { ZCryptId } from '~/common/schema/util'

const ZTypeFilter = Object.assign(ZRelationTypeValues.array(), { type: 'type' as const })

export const ZRelationQueryFilters = z.object({
  party: ZStringFilter.optional(),
  type: ZTypeFilter.optional(),
  endDate: ZDateFilter.optional(),
  startDate: ZDateFilter.optional(),
})

export interface ZRelationQueryFilters extends z.infer<typeof ZRelationQueryFilters> {}

export const ZRelationFilters = ZRelationQueryFilters.extend({
  fundraisingCryptId: ZCryptId.optional(),
})

export interface ZRelationFilters extends z.infer<typeof ZRelationFilters> {}
