import a from 'indefinite'
import type { WithId } from 'mongodb'
import type { z } from 'zod'
import type { ZDocType } from '~/common/schema'
import type { ZRelationTypeValues } from '~/common/schema/relation'
import { ZEquityType, ZPersonnelType, equityUtilTypes } from '~/common/schema/relation'
import { docTypeStr } from '~/common/schema/type-map'
import { objectEntriesTypesafe } from '~/common/util'
import type { AllMongoRelationMap } from '~/server/mongo/schema/relation'
import { type RedFlagInstance, RedFlagRule } from './base'

class RelationDocRedFlagRule<
  R extends ZRelationTypeValues,
  D extends ZDocType,
  T extends `${R}_REQUIRES_${D}`,
> extends RedFlagRule<WithId<z.infer<AllMongoRelationMap[R]>> & { docTypes: ZDocType[] }, T> {
  constructor(
    public type: T,
    public relationType: R,
    public docType: D
  ) {
    super(
      type,
      'RELATION_REQUIRES_DOC',
      (relationDisplay) => {
        const docStr = docTypeStr(docType)
        return `${relationDisplay} is missing ${a(docStr, { caseInsensitive: true })}`
      },
      (relationDisplay) => {
        const docStr = docTypeStr(docType, true)
        return `${relationDisplay} requires ${docStr}`
      },
      (relation) => {
        const hasRequiredType = relation.docTypes.some((dType) => dType === docType)

        if (!hasRequiredType)
          return {
            type,
            corpId: relation.corpId,
            primaryId: relation._id,
            active: true,
          }

        return null
      }
    )
  }
}

export const relationRedFlagRuleMap = {
  COMMON: [
    new RelationDocRedFlagRule(
      'COMMON_REQUIRES_COMMON_STOCK_PURCHASE_AGREEMENT',
      'COMMON',
      'COMMON_STOCK_PURCHASE_AGREEMENT'
    ),
    new RelationDocRedFlagRule(
      'COMMON_REQUIRES_SECTION_83B_ELECTION_FORM',
      'COMMON',
      'SECTION_83B_ELECTION_FORM'
    ),
    new RelationDocRedFlagRule(
      'COMMON_REQUIRES_BOARD_CONSENT_AND_MINUTES',
      'COMMON',
      'BOARD_CONSENT_AND_MINUTES'
    ),
  ],
  OPTION: [
    new RelationDocRedFlagRule(
      'OPTION_REQUIRES_BOARD_CONSENT_AND_MINUTES',
      'OPTION',
      'BOARD_CONSENT_AND_MINUTES'
    ),
  ],
  SAFE: [
    new RelationDocRedFlagRule(
      'SAFE_REQUIRES_SIMPLE_AGREEMENT_FOR_FUTURE_EQUITY',
      'SAFE',
      'SIMPLE_AGREEMENT_FOR_FUTURE_EQUITY'
    ),
    new RelationDocRedFlagRule(
      'SAFE_REQUIRES_BOARD_CONSENT_AND_MINUTES',
      'SAFE',
      'BOARD_CONSENT_AND_MINUTES'
    ),
  ],
  CONVERTIBLE: [
    new RelationDocRedFlagRule(
      'CONVERTIBLE_REQUIRES_CONVERTIBLE_NOTE',
      'CONVERTIBLE',
      'CONVERTIBLE_NOTE'
    ),
    new RelationDocRedFlagRule(
      'CONVERTIBLE_REQUIRES_BOARD_CONSENT_AND_MINUTES',
      'CONVERTIBLE',
      'BOARD_CONSENT_AND_MINUTES'
    ),
  ],
  WARRANT: [
    new RelationDocRedFlagRule(
      'WARRANT_REQUIRES_WARRANT_AGREEMENT',
      'WARRANT',
      'WARRANT_AGREEMENT'
    ),
    new RelationDocRedFlagRule(
      'WARRANT_REQUIRES_BOARD_CONSENT_AND_MINUTES',
      'WARRANT',
      'BOARD_CONSENT_AND_MINUTES'
    ),
  ],
  OPTIONPLAN: [
    new RelationDocRedFlagRule(
      'OPTIONPLAN_REQUIRES_STOCK_OPTION_PLAN',
      'OPTIONPLAN',
      'STOCK_OPTION_PLAN'
    ),
    new RelationDocRedFlagRule(
      'OPTIONPLAN_REQUIRES_BOARD_CONSENT_AND_MINUTES',
      'OPTIONPLAN',
      'BOARD_CONSENT_AND_MINUTES'
    ),
    new RelationDocRedFlagRule(
      'OPTIONPLAN_REQUIRES_STOCKHOLDER_CONSENT',
      'OPTIONPLAN',
      'STOCKHOLDER_CONSENT'
    ),
  ],
  VALUATION: [
    new RelationDocRedFlagRule('VALUATION_REQUIRES__409A_REPORT', 'VALUATION', '_409A_REPORT'),
    new RelationDocRedFlagRule(
      'VALUATION_REQUIRES_BOARD_CONSENT_AND_MINUTES',
      'VALUATION',
      'BOARD_CONSENT_AND_MINUTES'
    ),
  ],
  FUNDRAISING: [
    new RelationDocRedFlagRule(
      'FUNDRAISING_REQUIRES_PREFERRED_STOCK_PURCHASE_AGREEMENT',
      'FUNDRAISING',
      'PREFERRED_STOCK_PURCHASE_AGREEMENT'
    ),
    new RelationDocRedFlagRule(
      'FUNDRAISING_REQUIRES_VOTING_AGREEMENT',
      'FUNDRAISING',
      'VOTING_AGREEMENT'
    ),
    new RelationDocRedFlagRule(
      'FUNDRAISING_REQUIRES_RIGHT_OF_FIRST_REFUSAL_AND_COSALE_AGREEMENT',
      'FUNDRAISING',
      'RIGHT_OF_FIRST_REFUSAL_AND_COSALE_AGREEMENT'
    ),
    new RelationDocRedFlagRule(
      'FUNDRAISING_REQUIRES_INVESTOR_RIGHTS_AGREEMENT',
      'FUNDRAISING',
      'INVESTOR_RIGHTS_AGREEMENT'
    ),
    new RelationDocRedFlagRule(
      'FUNDRAISING_REQUIRES_BOARD_CONSENT_AND_MINUTES',
      'FUNDRAISING',
      'BOARD_CONSENT_AND_MINUTES'
    ),
    new RelationDocRedFlagRule(
      'FUNDRAISING_REQUIRES_STOCKHOLDER_CONSENT',
      'FUNDRAISING',
      'STOCKHOLDER_CONSENT'
    ),
    new RelationDocRedFlagRule(
      'FUNDRAISING_REQUIRES_ARTICLES_OF_INCORPORATION',
      'FUNDRAISING',
      'ARTICLES_OF_INCORPORATION'
    ),
  ],
  EMPLOYEE: [
    new RelationDocRedFlagRule('EMPLOYEE_REQUIRES_OFFER_LETTER', 'EMPLOYEE', 'OFFER_LETTER'),
    new RelationDocRedFlagRule(
      'EMPLOYEE_REQUIRES_PROPRIETARY_INFORMATION_AND_INVENTION_ASSIGNMENT',
      'EMPLOYEE',
      'PROPRIETARY_INFORMATION_AND_INVENTION_ASSIGNMENT'
    ),
  ],
  OFFICER: [
    new RelationDocRedFlagRule(
      'OFFICER_REQUIRES_BOARD_CONSENT_AND_MINUTES',
      'OFFICER',
      'BOARD_CONSENT_AND_MINUTES'
    ),
    new RelationDocRedFlagRule(
      'OFFICER_REQUIRES_INDEMNIFICATION_AGREEMENT',
      'OFFICER',
      'INDEMNIFICATION_AGREEMENT'
    ),
  ],
  DIRECTOR: [
    new RelationDocRedFlagRule(
      'DIRECTOR_REQUIRES_STOCKHOLDER_CONSENT',
      'DIRECTOR',
      'STOCKHOLDER_CONSENT'
    ),
    new RelationDocRedFlagRule(
      'DIRECTOR_REQUIRES_INDEMNIFICATION_AGREEMENT',
      'DIRECTOR',
      'INDEMNIFICATION_AGREEMENT'
    ),
  ],
  ADVISOR: [
    new RelationDocRedFlagRule(
      'ADVISOR_REQUIRES_ADVISOR_AGREEMENT',
      'ADVISOR',
      'ADVISOR_AGREEMENT'
    ),
    new RelationDocRedFlagRule(
      'ADVISOR_REQUIRES_BOARD_CONSENT_AND_MINUTES',
      'ADVISOR',
      'BOARD_CONSENT_AND_MINUTES'
    ),
  ],
  // these relation types do not have required types but they're defined here to
  // allow indexing this object with a relation type
  LOCAL: [],
  PREFERRED: [],
  STATE: [],
} as const

export const relationRedFlagRules = Object.values(relationRedFlagRuleMap).flat()

export const relationRedFlagTypes = relationRedFlagRules.map((rule) => rule.type)

export type RelationRedFlagType = (typeof relationRedFlagTypes)[number]

export interface RelationRedFlagInstance extends RedFlagInstance<RelationRedFlagType> {}

export const getRelationDocRules = (
  relationType: ZRelationTypeValues
): typeof relationRedFlagRules =>
  [...relationRedFlagRuleMap[relationType]].filter((rule) => rule instanceof RelationDocRedFlagRule)

export const relationRedFlagRuleTypeMap = new Map(
  relationRedFlagRules.map((rule) => [rule.type, rule])
)

export const personnelRedFlagTypes = objectEntriesTypesafe(relationRedFlagRuleMap)
  .filter(([key]) => ZPersonnelType.isType(key))
  .map(([_, rules]) => rules)
  .flat()
  .map((rule) => rule.type)

export const equityRedFlagTypes = objectEntriesTypesafe(relationRedFlagRuleMap)
  .filter(([key]) => ZEquityType.isType(key) || equityUtilTypes.includes(key))
  .map(([_, rules]) => rules)
  .flat()
  .map((rule) => rule.type)
