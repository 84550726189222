import type { DonutChartCell } from '@mantine/charts'
import { DonutChart } from '@mantine/charts'
import { Card, Group, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core'
import { IconAlertOctagon, IconCircleFilled } from '@tabler/icons-react'
import type { UseQueryResult } from '@tanstack/react-query'
import React from 'react'
import { any, uniq } from 'underscore'
import { LoadingErrorComp } from '~/client/components/util/error'
import { zIndex } from '~/client/components/z-index'
import { theme } from '~/client/lib/theme'
import type { ZAugmentedCorp } from '~/common/schema'
import { AugmentedMetadataNumber, exceedsMaximumSharesEquitySummaryError } from '~/common/schema'
import { ZEquitySummary } from '~/common/schema/relation'

interface EquitySummaryChartProps {
  equitySummary: Pick<
    UseQueryResult<{ equitySummary: ZEquitySummary } | undefined>,
    'data' | 'isLoading' | 'error' | 'isError'
  >
  corpData: Pick<UseQueryResult<ZAugmentedCorp>, 'data' | 'isLoading' | 'error' | 'isError'>
}

interface CustomCell extends DonutChartCell {
  warning?: string
}

const ChartLegend: React.FC<{ legends: Omit<DonutChartCell, 'value'>[] }> = ({ legends }) => (
  <Stack>
    {legends.map(({ name, color }) => (
      <Group gap='md' key={name}>
        <ThemeIcon color={color}>
          <IconCircleFilled size={20} />
        </ThemeIcon>
        <Text>{name}</Text>
      </Group>
    ))}
  </Stack>
)

const ChartCard: React.FC = ({ children }) => (
  <Card padding='xl' radius='md' withBorder shadow='sm'>
    <Group h='100%' align='center' gap='xl'>
      {children}
    </Group>
  </Card>
)

const sumChartValues = (parts: DonutChartCell[]) => parts.reduce((acc, { value }) => acc + value, 0)
const defaultWarningAggregate = { value: 0 }

const mkOptionPoolChartData = ({
  optionShares = defaultWarningAggregate,
  commonPoolShares = defaultWarningAggregate,
  optionPoolRemaining = defaultWarningAggregate,
}: Partial<ZEquitySummary>): CustomCell[] => [
  {
    name: ZEquitySummary.shape.optionShares.displaySchema.display,
    color: theme.colors.teal[6],
    ...optionShares,
  },
  {
    name: ZEquitySummary.shape.commonPoolShares.displaySchema.display,
    color: theme.colors.lime[6],
    ...commonPoolShares,
  },
  {
    name: ZEquitySummary.shape.optionPoolRemaining.displaySchema.display,
    color: theme.colors.gray[4],
    ...optionPoolRemaining,
  },
]

const mkAuthorizedSharesChartData = (
  {
    preferredShares = defaultWarningAggregate,
    warrantShares = defaultWarningAggregate,
    commonShares = defaultWarningAggregate,
    optionPoolTotal = defaultWarningAggregate,
  }: Partial<ZEquitySummary>,
  authorizedShares = 0
) => {
  const diluted = [
    {
      name: ZEquitySummary.shape.preferredShares.displaySchema.display,
      color: theme.colors.violet[6],
      ...preferredShares,
    },
    {
      name: ZEquitySummary.shape.commonShares.displaySchema.display,
      color: theme.colors.indigo[6],
      ...commonShares,
    },
    {
      name: ZEquitySummary.shape.warrantShares.displaySchema.display,
      color: theme.colors.blue[6],
      ...warrantShares,
    },
    {
      name: ZEquitySummary.shape.optionPoolTotal.displaySchema.display,
      color: theme.colors.blue[4],
      ...optionPoolTotal,
    },
  ]
  const totalDiluted = sumChartValues(diluted)
  const remaining = authorizedShares - totalDiluted
  return [...diluted, { name: 'Remaining Shares', value: remaining, color: theme.colors.gray[4] }]
}

interface CustomDonutChartProps extends EquitySummaryChartProps {
  data: CustomCell[]
  chartLabel: string | React.ReactNode
}

const CustomDonutChart: React.FC<CustomDonutChartProps> = ({
  data,
  equitySummary,
  corpData,
  chartLabel,
}) => {
  const error = any(data, (part) => part.value < 0)
  const warnings = React.useMemo(
    () => uniq(data.map((part) => part.warning).filter(Boolean)),
    [data]
  )
  const nonNegativeData = data.map((part) => ({ ...part, value: Math.max(0, part.value) }))
  const total = sumChartValues(nonNegativeData)
  const chartSize = 180

  return (
    <Stack w={chartSize} h={chartSize} justify='center' pos='relative'>
      <LoadingErrorComp queryResult={equitySummary} variant='tooltip'>
        <LoadingErrorComp queryResult={corpData} variant='tooltip'>
          <DonutChart
            valueFormatter={(value) => {
              return `${AugmentedMetadataNumber.display(value)} (${Math.round(
                (value / total) * 100
              )}%)`
            }}
            size={chartSize}
            thickness={25}
            tooltipDataSource='segment'
            data={nonNegativeData}
            // Casting is necessary because we want to pass React SVG nodes
            // to center the text
            chartLabel={chartLabel as string}
            tooltipProps={{
              wrapperStyle: { zIndex: zIndex.tooltip },
            }}
            styles={{
              label: {
                fontWeight: 'bold',
                fontSize: theme.fontSizes.lg,
              },
            }}
          />
          {error && (
            <Tooltip label={exceedsMaximumSharesEquitySummaryError}>
              <ThemeIcon color='danger' bottom={0} pos='absolute' right={0}>
                <IconAlertOctagon />
              </ThemeIcon>
            </Tooltip>
          )}
          {!error && warnings.length > 0 && (
            <Tooltip
              label={warnings.map((warning) => (
                <Text key={warning}>{warning}</Text>
              ))}
            >
              <ThemeIcon color='urgent' bottom={0} pos='absolute' right={0}>
                <IconAlertOctagon />
              </ThemeIcon>
            </Tooltip>
          )}
        </LoadingErrorComp>
      </LoadingErrorComp>
    </Stack>
  )
}

export const EquitySummaryCharts: React.FC<EquitySummaryChartProps> = ({
  equitySummary,
  corpData,
}) => {
  const authorizedSharesData = mkAuthorizedSharesChartData(
    equitySummary.data?.equitySummary ?? {},
    corpData.data?.authorizedShares?.value ?? undefined
  )
  const optionPoolData = mkOptionPoolChartData(equitySummary.data?.equitySummary ?? {})
  return (
    <Group align='stretch'>
      <ChartCard>
        <CustomDonutChart
          data={authorizedSharesData}
          equitySummary={equitySummary}
          corpData={corpData}
          chartLabel={
            // The chart label is rendered inside svg <text/>
            // So, we have to use <tspan/> to break lines
            // https://stackoverflow.com/questions/16701522/how-to-linebreak-an-svg-text-within-javascript
            <>
              {/* Negative value on y to be above the central line */}
              <tspan x='50%' dy='-0.5em'>
                Authorized
              </tspan>
              <tspan x='50%' dy='1.2em'>
                Shares
              </tspan>
            </>
          }
        />
        <ChartLegend legends={authorizedSharesData} />
      </ChartCard>

      <ChartCard>
        <CustomDonutChart
          data={optionPoolData}
          equitySummary={equitySummary}
          corpData={corpData}
          chartLabel='Equity Plan'
        />
        <ChartLegend legends={optionPoolData} />
      </ChartCard>
    </Group>
  )
}
