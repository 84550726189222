import { Accordion, Divider, Stack, Table, Title } from '@mantine/core'
import React from 'react'
import type { SortRenderer } from '~/client/components/relation/base-table'
import {
  RelationHeaders,
  RelationRow,
  RelationTable,
} from '~/client/components/relation/base-table'
import {
  RelationPartyDisplay,
  RelationPriceDisplay,
  RelationTypeBadgeDisplay,
} from '~/client/components/relation/display'
import { SortHeader } from '~/client/components/util/sort'
import { accordionRelationTableStickyCell } from '~/client/lib/css-util.css'
import { useRelationsByType } from '~/client/lib/hooks/query'
import { getDateCellValue } from '~/client/lib/relations-xlsx'
import { type EnhancedRelation, type RedFlagInfo, enhanceCount } from '~/common/enhance'
import {
  AugmentedMetadataNumber,
  AugmentedMetadataPercentage,
  AugmentedMetadataPrice2,
  AugmentedMetadataPrice4,
} from '~/common/schema'
import type { ZRelationQueryFilters } from '~/common/schema/relation'
import {
  type ZAugmentedFundraising,
  ZAugmentedRelation,
  type ZAugmentedSafesAndConvertibles,
} from '~/common/schema/relation'

const SafesAndConvertiblesRow: React.FC<{
  item: EnhancedRelation
  redFlagInfo: RedFlagInfo | undefined
}> = ({ children, ...props }) => {
  return (
    <RelationRow
      nameComp={<RelationPartyDisplay relation={props.item} />}
      stickyCellClassName={accordionRelationTableStickyCell}
      {...props}
    >
      {children}
    </RelationRow>
  )
}

const endedSafesAndConvertibleFilter: ZRelationQueryFilters = {
  endDate: { operator: 'before', value: new Date() },
} as const
const outstandingSafesAndConvertibleFilter: ZRelationQueryFilters = {
  endDate: { operator: 'since', value: new Date(), inclusive: true, includeNullValues: true },
}

const SafesAndConvertibles: React.FC = () => {
  // these queries are a copy of the first query on the relation tables so they
  // should only happen once between the ones here and the ones in the relation
  // table components. We need this here to get the relation counts
  const convertedSafesAndConvertibleQueryResult = useRelationsByType({
    limit: 10,
    skip: 0,
    types: ['SAFE', 'CONVERTIBLE'],
    filters: endedSafesAndConvertibleFilter,
    sortField: '_id',
    direction: 1,
  })
  const outstandingSafesAndConvertibleQueryResult = useRelationsByType({
    limit: 10,
    skip: 0,
    types: ['SAFE', 'CONVERTIBLE'],
    filters: outstandingSafesAndConvertibleFilter,
    sortField: '_id',
    direction: 1,
  })

  const renderHeaders: SortRenderer = (props) => (
    <RelationHeaders
      {...props}
      baseHeaderName={{
        name: 'Equity Holder',
        startDate: 'Issue Date',
        endDate: 'Conversion Date',
      }}
      stickyColBg='#f8f9fa'
      nameSortField='party.name'
      relationTypes={['SAFE', 'CONVERTIBLE']}
      renderCustomHeaders={(headerProps) => (
        <>
          <SortHeader {...headerProps} sortField='type'>
            Class
          </SortHeader>
          <Table.Th ta='end'>Investment</Table.Th>
          <Table.Th ta='end'>Valuation Cap</Table.Th>
          <Table.Th ta='end'>Discount</Table.Th>
          <Table.Th ta='end'>Interest</Table.Th>
        </>
      )}
    />
  )
  const renderRows = (
    data: {
      enhancedRelation: EnhancedRelation<ZAugmentedSafesAndConvertibles>
      redFlagInfo: RedFlagInfo | undefined
    }[]
  ) => (
    <>
      {data.map(({ enhancedRelation, redFlagInfo }) => (
        <SafesAndConvertiblesRow
          key={enhancedRelation.cryptId.idStr}
          redFlagInfo={redFlagInfo}
          item={enhancedRelation}
        >
          <Table.Td>
            <RelationTypeBadgeDisplay
              type={enhancedRelation.type}
              isInactive={!enhancedRelation.isActive}
              compact
            />
          </Table.Td>
          <Table.Td>
            <RelationPriceDisplay
              schema={AugmentedMetadataPrice2}
              value={enhancedRelation.investment?.value}
            />
          </Table.Td>
          <Table.Td>
            <RelationPriceDisplay
              schema={AugmentedMetadataNumber}
              value={enhancedRelation.valuationCap?.value}
            />
          </Table.Td>
          <Table.Td>
            <RelationPriceDisplay
              schema={AugmentedMetadataPercentage}
              value={enhancedRelation.discount?.value}
            />
          </Table.Td>
          <Table.Td>
            <RelationPriceDisplay
              schema={AugmentedMetadataPercentage}
              value={
                enhancedRelation.type === 'CONVERTIBLE'
                  ? enhancedRelation.interest?.value
                  : undefined
              }
            />
          </Table.Td>
        </SafesAndConvertiblesRow>
      ))}
    </>
  )

  const accordionData = [
    {
      value: 'Converted SAFEs and Convertible notes',
      noDataMsg: 'No Converted SAFEs or Convertible notes found',
      sheetTitle: 'Converted SAFEs and CNs',
      filters: endedSafesAndConvertibleFilter,
      count: convertedSafesAndConvertibleQueryResult.data?.count,
    },
    {
      value: 'Outstanding SAFEs and Convertible notes',
      noDataMsg: 'No Outstanding SAFEs or Convertible notes found',
      sheetTitle: 'Outstanding SAFEs and CNs',
      filters: outstandingSafesAndConvertibleFilter,
      count: outstandingSafesAndConvertibleQueryResult.data?.count,
    },
  ]

  return (
    <Accordion variant='contained'>
      {accordionData.map(({ value, filters, count, noDataMsg, sheetTitle }) => (
        <Accordion.Item key={value} value={value}>
          <Accordion.Control>
            <Title order={4}>
              {value} {count ? `(${enhanceCount(count)})` : ''}
            </Title>
          </Accordion.Control>
          <Accordion.Panel>
            <RelationTable
              relationTypes={['SAFE', 'CONVERTIBLE']}
              relationFilters={filters}
              renderRows={renderRows}
              renderHeaders={renderHeaders}
              noDataMsg={noDataMsg}
              downloadOptions={{
                // we do not want to have "deleted" for missing indexes because
                // the relation types on this table ("SAFE" and "CONVERTIBLE")
                // are not indexed together
                excludeMissingIndexes: true,
                sheetTitle,
                headers: [
                  'Name',
                  'Email',
                  'Issue Date',
                  'Conversion Date',
                  'Class',
                  'Investment',
                  'Valuation Cap',
                  'Discount',
                  'Interest',
                  'Number of Documents',
                ],
                rowProvider: (r) => {
                  const { name, email } = ZAugmentedRelation.partyNameEmailWithIndex(r)
                  return [
                    name,
                    email,
                    getDateCellValue(r.startDate?.value),
                    getDateCellValue(r.endDate?.value),
                    r.type,
                    r.investment ? AugmentedMetadataPrice2.display(r.investment.value) : '',
                    r.valuationCap?.value?.toLocaleString() ?? '',
                    r.discount ? AugmentedMetadataPercentage.display(r.discount.value) : '',
                    'interest' in r && r.interest
                      ? AugmentedMetadataPercentage.display(r.interest.value)
                      : '',
                    r.docs.length,
                  ]
                },
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  )
}

export const FundraisingComp: React.FC = () => {
  const renderHeaders: SortRenderer = (props) => (
    <RelationHeaders
      baseHeaderName={{ name: 'Fundraising Round', startDate: 'Date' }}
      relationTypes={['FUNDRAISING']}
      noEndDate
      renderCustomHeaders={() => (
        <>
          <Table.Th>Shares</Table.Th>
          <Table.Th>Share Price</Table.Th>
        </>
      )}
      {...props}
    />
  )
  const renderRows = (
    data: {
      enhancedRelation: EnhancedRelation<ZAugmentedFundraising>
      redFlagInfo: RedFlagInfo | undefined
    }[]
  ) => (
    <>
      {data.map((fundraisingData) => (
        <RelationRow
          noEndDate
          redFlagInfo={fundraisingData.redFlagInfo}
          key={fundraisingData.enhancedRelation.cryptId.idStr}
          item={fundraisingData.enhancedRelation}
        >
          <Table.Td>
            <RelationPriceDisplay
              schema={AugmentedMetadataNumber}
              value={fundraisingData.enhancedRelation.shares?.value}
            />
          </Table.Td>
          <Table.Td>
            <RelationPriceDisplay
              schema={AugmentedMetadataPrice4}
              value={fundraisingData.enhancedRelation.sharePrice?.value}
            />
          </Table.Td>
        </RelationRow>
      ))}
    </>
  )
  return (
    <Stack>
      <RelationTable
        relationTypes={['FUNDRAISING']}
        renderHeaders={renderHeaders}
        renderRows={renderRows}
        noDataMsg='No Fundraising records found'
        downloadOptions={{
          sheetTitle: 'Fundraising Round',
          headers: ['Fundraising Round', 'Date', 'Shares', 'Share Price', 'Number of Documents'],
          rowProvider: (r) => [
            r.display,
            getDateCellValue(r.startDate?.value),
            AugmentedMetadataNumber.display(r.shares?.value),
            AugmentedMetadataPrice4.display(r.sharePrice?.value),
            r.docs.length,
          ],
        }}
      />
      <Divider my='xl' />
      <SafesAndConvertibles />
    </Stack>
  )
}
