import { Box, Group, Stack, Table, ThemeIcon, Tooltip } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { EquitySummaryCharts } from '~/client/components/relation/equity/charts'
import { NoDataComp } from '~/client/components/util'
import { ErrorComp } from '~/client/components/util/error'
import {
  ComputedMetadataRow,
  MetadataRow,
  MiniObjectMetadataRow,
} from '~/client/components/util/metadata'
import { hooks, useOptimisticCorpUpdate } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { enhanceCorp } from '~/common/enhance'
import { ZAugmentedCorp } from '~/common/schema'
import { ZEquitySummary } from '~/common/schema/relation/augmented'
import { IndentedText } from './util'

export const CapTableSummary: React.FC = () => {
  const corpQueryResult = hooks.trpc().corp.read.useQueryWithCorp()
  const equityDataQueryResult = hooks.trpc().relation.equity.equityData.useQueryWithCorp()
  const updateCorp = useOptimisticCorpUpdate()

  const enhancedCorp = corpQueryResult.data ? enhanceCorp(corpQueryResult.data) : undefined
  const equityData = equityDataQueryResult.data

  const {
    preferredShares,
    commonShares,
    warrantShares,
    optionPoolRemaining,
    optionPoolTotal,
    commonPoolShares,
    fullyDiluted,
    optionPoolUsed,
    optionShares,
  } = equityData?.equitySummary ?? {}

  const error = corpQueryResult.error || equityDataQueryResult.error
  const isLoading = corpQueryResult.isLoading || equityDataQueryResult.isLoading
  const isError = corpQueryResult.isError || equityDataQueryResult.isError

  const refetch = useCallback(async () => {
    if (corpQueryResult.isError) await corpQueryResult.refetch()
    if (equityDataQueryResult.isError) await equityDataQueryResult.refetch()
  }, [corpQueryResult, equityDataQueryResult])

  const noData = !isLoading && (!equityData || !enhancedCorp)

  return (
    <Stack gap='xl' mt='lg'>
      <EquitySummaryCharts corpData={corpQueryResult} equitySummary={equityDataQueryResult} />
      <Box
        style={{
          maxWidth: theme.other.widths.md,
        }}
        mb='xl'
        data-testid='cap-table-summary'
      >
        <Table striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th />
              <Table.Th ta='end'>Information</Table.Th>
              <Table.Th>Source Document</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <ErrorComp fullTableWidth queryResult={{ error, isError, refetch }}>
              {noData ? (
                <NoDataComp message='No Cap Table Information found' />
              ) : (
                <>
                  <MetadataRow
                    isLoading={isLoading}
                    displayProps={ZAugmentedCorp.shape.authorizedShares.mkDisplayProps({
                      data: enhancedCorp ?? { supportedTypes: [] }, // Fallback just to satisfy TS
                      update: (data) => updateCorp.mutateAsync({ data }),
                      property: 'authorizedShares',
                    })}
                    alignment='end'
                  />
                  <ComputedMetadataRow
                    isLoading={isLoading}
                    display={ZEquitySummary.shape.preferredShares.displaySchema.display}
                    value={ZEquitySummary.shape.preferredShares.displaySchema.displayFn(
                      preferredShares?.value
                    )}
                    warning={preferredShares?.warning}
                  />
                  <ComputedMetadataRow
                    isLoading={isLoading}
                    display={ZEquitySummary.shape.commonShares.displaySchema.display}
                    value={ZEquitySummary.shape.commonShares.displaySchema.displayFn(
                      commonShares?.value
                    )}
                    warning={commonShares?.warning}
                  />
                  <ComputedMetadataRow
                    isLoading={isLoading}
                    display={ZEquitySummary.shape.warrantShares.displaySchema.display}
                    value={ZEquitySummary.shape.warrantShares.displaySchema.displayFn(
                      warrantShares?.value
                    )}
                    warning={warrantShares?.warning}
                  />
                  <MiniObjectMetadataRow
                    isLoading={isLoading}
                    display={ZEquitySummary.shape.optionPoolTotal.displaySchema.display}
                    value={ZEquitySummary.shape.optionPoolTotal.displaySchema.displayFn(
                      optionPoolTotal?.value
                    )}
                    warning={optionPoolTotal?.warning}
                    source={equityData?.latestPlan}
                  />
                  <ComputedMetadataRow
                    isLoading={isLoading}
                    display={
                      <IndentedText>
                        {ZEquitySummary.shape.optionShares.displaySchema.display}
                      </IndentedText>
                    }
                    value={ZEquitySummary.shape.optionShares.displaySchema.displayFn(
                      optionShares?.value
                    )}
                    warning={optionShares?.warning}
                  />
                  <ComputedMetadataRow
                    isLoading={isLoading}
                    display={
                      <IndentedText>
                        {ZEquitySummary.shape.commonPoolShares.displaySchema.display}
                      </IndentedText>
                    }
                    value={ZEquitySummary.shape.commonPoolShares.displaySchema.displayFn(
                      commonPoolShares?.value
                    )}
                    warning={commonPoolShares?.warning}
                  />
                  <ComputedMetadataRow
                    isLoading={isLoading}
                    display={
                      <IndentedText>
                        {ZEquitySummary.shape.optionPoolUsed.displaySchema.display}
                      </IndentedText>
                    }
                    value={ZEquitySummary.shape.optionPoolUsed.displaySchema.displayFn(
                      optionPoolUsed?.value
                    )}
                    warning={optionPoolUsed?.warning}
                  />
                  <ComputedMetadataRow
                    isLoading={isLoading}
                    display={
                      <IndentedText>
                        {ZEquitySummary.shape.optionPoolRemaining.displaySchema.display}
                      </IndentedText>
                    }
                    value={ZEquitySummary.shape.optionPoolRemaining.displaySchema.displayFn(
                      optionPoolRemaining?.value
                    )}
                    warning={optionPoolRemaining?.warning}
                  />
                  <ComputedMetadataRow
                    isLoading={isLoading}
                    display={
                      <Group gap='xs'>
                        <span>{ZEquitySummary.shape.fullyDiluted.displaySchema.display}</span>
                        <Tooltip
                          withinPortal
                          label='Sum of preferred shares, warrant shares, common shares not issued under the equity plan, and the entire equity incentive plan. Excludes SAFEs and Convertible Notes'
                          multiline
                          maw={theme.other.widths.xs}
                        >
                          <ThemeIcon color='primary' size='sm'>
                            <IconInfoCircle />
                          </ThemeIcon>
                        </Tooltip>
                      </Group>
                    }
                    value={ZEquitySummary.shape.fullyDiluted.displaySchema.displayFn(
                      fullyDiluted?.value
                    )}
                    warning={fullyDiluted?.warning}
                  />
                </>
              )}
            </ErrorComp>
          </Table.Tbody>
        </Table>
      </Box>
    </Stack>
  )
}
